import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { XseriesVideoProps } from "./Xseries.d"
import { Image } from "../../atoms/Image"
import { Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import { parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import ExternalVideo from "../../molecules/VideoSection/ExternalVideo"
import { TealiumContext } from "../../../contexts/Tealium"
import VideoS3 from "../../molecules/VideoSection/VideoS3"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XseriesVideo: React.FC<XseriesVideoProps> = ({
  videoSectionBackground,
  videoSectionHeading,
  videoSectionSubheading,
  videoSectionTitle,
  videoSectionCTAText,
  videoSectionVideo,
  video_s3,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)
  const { vehicleTealData } = useContext(TealiumContext)

  function handleClose() {
    setModalOpen(!modalOpen)
  }

  return (
    <section
      aria-label="XSeries Video Section"
      css={[
        tw`flex h-auto bg-[#ccc] relative`,
        tw`md:(items-center h-[680px])`,
      ]}
    >
      {videoSectionBackground && (
        <Image
          src={videoSectionBackground.asset.gatsbyImageData}
          css={[
            tw`absolute top-0 w-full h-auto object-cover`,
            "z-index: 0;",
            tw`md:(h-[680px])`,
          ]}
        />
      )}
      <div
        css={[
          tw`ml-0 w-full z-20 bg-white p-10`,
          tw`md:(w-auto ml-20 mb-60 rounded-2xl bg-opacity-90)`,
        ]}
      >
        <span css={[tw`uppercase block font-semibold mb-4`]}>
          {_(videoSectionTitle)}
        </span>
        <h3 css={[tw`text-xl font-semibold mb-4`, tw`md:(text-2xl mb-4)`]}>
          {parseDisclaimerBlocks(videoSectionHeading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h3>
        <h4
          css={[
            tw`text-2xl tracking-widest font-light leading-normal mb-6`,
            tw`md:(text-4xl mb-6 w-96)`,
          ]}
        >
          {parseDisclaimerBlocks(videoSectionSubheading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h4>
        <Button secondary onClick={() => setModalOpen(true)}>
          {_(videoSectionCTAText)}
        </Button>
      </div>
      {modalOpen && (
        <div
          css={[
            tw`fixed top-0 bottom-0 w-full bg-[#000] h-full text-white p-12 pt-24`,
            "z-index: 60",
          ]}
        >
          <button
            onClick={() => handleClose()}
            css={[tw`flex items-center text-xs`]}
          >
            <Icon.Chevron
              color="white"
              direction="left"
              css={[tw`max-h-4 mr-2 w-4`]}
            />
            {_("BACK")}
          </button>

          {/* ExternalVideo component allows for Tealium event tagging */}
          {videoSectionVideo && (
            <ExternalVideo
              videoSectionVideo={videoSectionVideo}
              videoSectionHeading={videoSectionHeading.slice(0, 29)} // SDR requires heading trimmed to max 30 characters
              autoPlay
              videoCss={[tw`h-1/3 w-full md:h-5/6 p-12`]} // Custom CSS for video
              extraTealiumValues={{
                ...(vehicleTealData?.vehicle_model && {
                  vehicle_model: vehicleTealData?.vehicle_model,
                }),
                ...(vehicleTealData?.vehicle_model_truncated && {
                  vehicle_model_truncated:
                    vehicleTealData?.vehicle_model_truncated,
                }),
                ...(vehicleTealData?.vehicle_year && {
                  vehicle_year: vehicleTealData?.vehicle_year,
                }),
                ...(vehicleTealData?.vehicle_segment_vehicle_page && {
                  vehicle_segment_vehicle_page:
                    vehicleTealData?.vehicle_segment_vehicle_page,
                }),
              }} // Custom Tealium values
            />
          )}
          {video_s3 && (
            <VideoS3
              videoSectionHeading={videoSectionHeading}
              video_s3={video_s3}
            />
          )}
          <div css={tw`text-white`}>
            <h2 css={[tw`text-base font-semibold`]}>
              {parseDisclaimerBlocks(videoSectionHeading, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </h2>
            <h4
              css={[
                tw`text-base tracking-widest font-light leading-normal mb-6`,
              ]}
            >
              {parseDisclaimerBlocks(videoSectionSubheading, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </h4>
          </div>
        </div>
      )}
    </section>
  )
}

export default XseriesVideo
