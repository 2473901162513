import React from "react"
import tw from "twin.macro"
import { SimpleGallery } from "../../molecules/SimpleGallery"
import { XSeriesGalleryProps } from "./Xseries.d"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XSeriesGallery: React.FC<XSeriesGalleryProps> = ({ galleryImages }) => {
  return (
    <section
      aria-label="XSeries Gallery Section"
      css={[tw`bg-black pt-12 pb-32 relative`]}
    >
      <div
        css={[
          tw`max-w-screen-xl mx-auto px-8 text-center text-white mb-10 relative z-10`,
        ]}
      ></div>
      {galleryImages && <SimpleGallery slides={galleryImages} />}
    </section>
  )
}

export default XSeriesGallery
