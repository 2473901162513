import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { XSeriesDetailsProps } from "./Xseries.d"
import { Image } from "../../atoms/Image"
import { LocationContext } from "../../../contexts/Location"
import Icon from "../../atoms/Icon"
import { Link } from "../../atoms/Link"
import { css } from "@emotion/react"
import { LanguageContext } from "../../../contexts/Language"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import {
  generateInventoryLink,
  getCTALinkValue,
  parseDisclaimerBlocks,
} from "../../../helpers"
import { ButtonLink } from "../../atoms/Button"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XSeriesDetails: React.FC<XSeriesDetailsProps> = ({
  modelsSectionHeading,
  modelsSectionTagline,
  modelsSectionFeatures,
  modelsSectionDescription,
  modelsSectionLogo,
  ctaModelSection,
  modelSectionDescriptionTwo,
  models,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)

  //group the models as an array where the series.name is the same
  const groupedModels = models.reduce((r, a) => {
    r[a.series.name] = [...(r[a.series.name] || []), a]
    return r
  }, {})

  const [{ city, dealers }] = useContext(LocationContext)
  const { _ } = useContext(LanguageContext)

  const hoverAnimation = css`
    &:hover > .darkRed {
      ${tw`translate-x-11 duration-300`}
    }
    &:hover > .lighterRed {
      ${tw`opacity-100 delay-200`}
    }
    &:hover > .lightestRed {
      ${tw`opacity-100 delay-100`}
    }
  `

  //this function is just a prelimary way to get images by angle and a color -- will need some revisions but works for now
  const getExteriorImageByColorAndAngle = (
    colorCode: string,
    model: any,
    angle: number
  ) => {
    //get the color from the colors object where exterior.code is the same as the color
    const exteriorColor = model.colors.find(
      (c: { exterior: { code: string } }) => c.exterior.code === colorCode
    )

    if (!exteriorColor) {
      //if we dont have a color that matches the color code supplied then take the first one
      return model.colors[0].exteriorImages[angle]
    }
    return exteriorColor?.exteriorImages
      ? exteriorColor?.exteriorImages[angle]
      : model.jellybean
  }
  return (
    <section
      aria-label="XSeries Details Section"
      css={[
        tw`grid overflow-hidden grid-cols-12 mx-auto mb-6 grid-rows-1 grid-flow-row w-auto h-auto lg:(max-w-7xl gap-20)`,
      ]}
    >
      <div css={[tw`col-span-12 mb-6 text-center`]}>
        <h2 css={[tw`font-semibold mt-12 text-xl md:(text-2xl) lg:(text-4xl)`]}>
          {parseDisclaimerBlocks(modelsSectionHeading, code =>
            dispatch(toggleDisclaimersModal(code))
          )}
        </h2>
      </div>
      <div css={[tw`col-span-12 mx-6`, tw`md:(col-span-6)`]}>
        <p>
          {parseDisclaimerBlocks(modelsSectionDescription, code =>
            dispatch(toggleDisclaimersModal(code))
          )}
        </p>
        <div css={[tw`text-center`]}>
          <Image imageData={modelsSectionLogo} />
          <h3
            css={[
              tw`block text-center text-4xl font-semibold mb-10 md:(mb-20)`,
            ]}
          >
            {parseDisclaimerBlocks(modelsSectionTagline, code =>
              dispatch(toggleDisclaimersModal(code))
            )}
          </h3>
        </div>
        <div
          css={[
            tw`bg-gray-200 rounded-2xl pl-10 py-6 pr-4 col-start-1 row-start-2 col-span-12 mt-4 mb-8 md:( row-start-1 col-span-7 p-14 mt-0 mb-12)`,
          ]}
        >
          <ul
            css={[
              tw`text-black md:(grid grid-cols-2 grid-rows-1 gap-2 grid-flow-row w-auto h-auto)`,
            ]}
          >
            {modelsSectionFeatures.map((feature, index) => (
              <li css={[tw`list-disc list-outside text-base font-semibold`]}>
                {parseDisclaimerBlocks(feature, code =>
                  dispatch(toggleDisclaimersModal(code))
                )}
              </li>
            ))}
          </ul>
        </div>
        {modelSectionDescriptionTwo && (
          <p css={[tw`pb-6`]}>{modelSectionDescriptionTwo}</p>
        )}
        {ctaModelSection && (
          <ButtonLink
            {...{ [ctaModelSection?.buttonType]: true }}
            to={getCTALinkValue(ctaModelSection)}
            target={
              ctaModelSection?.linkType === "external" ? "_blank" : "_self"
            }
            key={ctaModelSection?.title}
          >
            {ctaModelSection?.title}
          </ButtonLink>
        )}
      </div>
      <div css={[tw`mb-12 col-span-12 ml-6`, tw`md:(col-span-6)`]}>
        <ul css={[tw``]}>
          <h4 css={[tw`text-xl mb-6 tracking-widest`]}>
            {_("Select XSeries™ Vehicle")}
          </h4>
          {Object.entries(groupedModels).map(([key, value]) => {
            const activeModelAccyCodes = [
              ...new Set(
                value.map((v: any) => v.accessoryCodes.split(",")).flat()
              ),
            ].join(",")
            return (
              <li css={[tw`list-none flex justify-center items-center pb-4`]}>
                <div css={[tw`w-2/5 relative`]}>
                  <h3 css={[tw`font-light text-lg uppercase`]}>{key}</h3>
                  {value.map((model: any) => (
                    <h4 css={[tw`font-semibold text-lg`]}>
                      <Link
                        to={`/xseries/${key.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        css={[tw`relative p-0`, hoverAnimation]}
                      >
                        {model.name == "XP " || model.name == "XP"
                          ? "XP Package"
                          : model.name}
                        <Icon.Chevron
                          direction="right"
                          color="red-200"
                          className="lightestRed"
                          css={[
                            tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-5 -translate-y-0.5 opacity-0`,
                          ]}
                        />
                        <Icon.Chevron
                          direction="right"
                          color="red-300"
                          className="lighterRed"
                          css={[
                            tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-8 -translate-y-0.5 opacity-0`,
                          ]}
                        />
                        <Icon.Chevron
                          direction="right"
                          color="red-400"
                          className="darkRed"
                          css={[
                            tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-5 -translate-y-0.5 duration-300`,
                          ]}
                        />
                      </Link>
                    </h4>
                  ))}
                  <h4 css={[tw`font-semibold text-lg`]}>
                    <Link
                      to={generateInventoryLink({
                        series: key.toLowerCase(),
                        accessoryModels: [activeModelAccyCodes],
                      })}
                      css={[tw`relative p-0`, hoverAnimation]}
                    >
                      XP Inventory
                      <Icon.Chevron
                        direction="right"
                        color="red-200"
                        className="lightestRed"
                        css={[
                          tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-5 -translate-y-0.5 opacity-0`,
                        ]}
                      />
                      <Icon.Chevron
                        direction="right"
                        color="red-300"
                        className="lighterRed"
                        css={[
                          tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-8 -translate-y-0.5 opacity-0`,
                        ]}
                      />
                      <Icon.Chevron
                        direction="right"
                        color="red-400"
                        className="darkRed"
                        css={[
                          tw`absolute max-h-4 inline-block ml-1 bottom-0 right-0 h-4 translate-x-5 -translate-y-0.5 duration-300`,
                        ]}
                      />
                    </Link>
                  </h4>
                </div>
                <div css={[tw`w-1/2 lg:(w-2/3)`]}>
                  <Image
                    imageData={getExteriorImageByColorAndAngle(
                      "0040",
                      value[0],
                      1
                    )}
                    css={[tw`object-contain`]}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default XSeriesDetails
