import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Play icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */

const Play: React.FC<IconProps> = ({
  color = "red-400",
  ...remainingProps
}) => {
  const colorString = processIconColor(color)

  return (
    <svg
      fill={colorString}
      viewBox="0 0 16 16"
      css={tw`w-16`}
      {...remainingProps}
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
    </svg>
  )
}

export default Play
