import React from "react"
import tw from "twin.macro"
import { XseriesHeaderGalleryProps } from "./Xseries.d"
import { Image } from "../../atoms/Image"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XSeriesHeaderGallery: React.FC<XseriesHeaderGalleryProps> = ({
  headerGallery,
}) => {
  return (
    <section
      aria-label="XSeries Image Gallery"
      css={[tw`flex flex-col`, tw`md:(flex-row)`]}
    >
      {headerGallery.map(image => (
        <div
          css={[
            headerGallery.length === 1 && tw`w-full md:(w-full)`,
            headerGallery.length === 2 && tw`w-full md:(w-1/2)`,
            headerGallery.length === 3 && tw`w-full md:(w-1/3)`,
            headerGallery.length === 4 && tw`w-full md:(w-1/4)`,
          ]}
        >
          <Image
            imageData={image.image}
            css={[tw`h-56 w-full object-cover`, tw`md:(h-72 mb-0)`]}
          />
        </div>
      ))}
    </section>
  )
}

export default XSeriesHeaderGallery
