import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import OthersAlsoBought from "../components/molecules/OthersAlsoBought/OthersAlsoBought"
import XSeriesHeader from "../components/organisms/Xseries/Header"
import XseriesVideo from "../components/organisms/Xseries/Video"
import { Pixlee } from "../components/molecules/Pixlee"
import XSeriesHeaderGallery from "../components/organisms/Xseries/HeaderGallery"
import XSeriesDetails from "../components/organisms/Xseries/Details"
import XSeriesGallery from "../components/organisms/Xseries/Gallery"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import XSeriesFamilySeries from "../components/organisms/Xseries/FamilySeries"

const XSeriesPage: React.FC<any> = ({ data, location }) => {
  const { page } = data

  //Tealium

  const { handleTealView } = useTealiumView()

  useEffect(() => {
    handleTealView({
      page_name: "x-series",
      tealium_event: "x-series_lander",
      page_type: "x-series",
    })
  }, [])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <XSeriesFamilySeries {...page} />
      <SanitySections data={page} />
    </Layout>
  )
}
const SanitySections = ({ data }: any): JSX.Element => {
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "xSeriesHero":
            return (
              <>
                <XSeriesHeader {...section} />
                <XSeriesHeaderGallery {...section} />
              </>
            )
          case "pixleeAlbum":
            return <Pixlee {...section} />
          case "videoSection":
            return <XseriesVideo {...section} />
          case "othersAlsoBoughtSection":
            return <OthersAlsoBought {...section} />
          case "xSeriesModelSection":
            return <XSeriesDetails {...section} />
          case "xSeriesGallery":
            return <XSeriesGallery {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query XSeriesPageQuery($id: String) {
    page: sanityPageXseries(id: { eq: $id }) {
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      language
      models {
        ... on SanityCustomModel {
          id
          year
          jellybean {
            asset {
              altText
              _id
              gatsbyImageData(placeholder: NONE)
            }
          }
          name
          series {
            ...seriesPageModelSeries
          }
          colors {
            ...color
          }
          costs {
            baseMSRP
          }
        }
      }
      content {
        ...PixleeSectionData
        ...OthersAlsoBoughtSectionData
        ...XSeriesVideoSection
        ...XSeriesGallerySection
        ...XSeriesHeroSection
        ...XSeriesModelSection
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default XSeriesPage
