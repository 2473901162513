import React, { useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { VideoS3Props } from "./Video.d"
import { Image } from "../../atoms/Image"
import Play from "../../atoms/Icon/Play"

const VideoS3: React.FC<VideoS3Props> = ({
  videoSectionHeading,
  video_s3,
  overlayImage,
  overlayImageStyles,
  ...remainingProps
}) => {
  const videoS3El = useRef(null)
  const { trackTealEvent } = useTealiumEvent()
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(false)
  }, [video_s3])

  // Tealium for video events using S3 hosted videos
  const percentWatched = () => {
    const percentage = Math.round(
      (videoS3El?.current.currentTime / videoS3El?.current?.duration) * 100
    )

    return percentage
  }
  const tealVideoEvent = (action: string, time?: string) => {
    const timeOnPause = `${percentWatched()}:${Math.round(
      videoS3El?.current?.currentTime
    )}`

    trackTealEvent({
      tealium_event: "video_event",
      video_type: `${`${videoSectionHeading}-${video_s3?.asset?._id}`}|${action}|${
        time ? time : timeOnPause
      }`,
    })
  }

  return (
    <div
      onClick={() => {
        setActive(true)
        videoS3El?.current?.play()
      }}
    >
      <video
        ref={videoS3El}
        id={video_s3?.asset?._id}
        controls={true}
        title={videoSectionHeading}
        src={`${process.env.GATSBY_ASSET_URL}${video_s3?.asset?.s3?.key}`}
        onPlay={() => {
          //only fire on initial play
          videoS3El?.current?.currentTime == 0 && tealVideoEvent("start", "0:0")
        }}
        onPause={() => {
          //don't fire on end of video
          videoS3El?.current?.currentTime !== videoS3El?.current?.duration &&
            tealVideoEvent("pause")
        }}
        onEnded={() => {
          tealVideoEvent(
            "ended",
            `100:${Math.round(videoS3El?.current?.duration)}`
          )
        }}
        css={[tw`h-3/4 w-full p-12`, tw`lg:(h-5/6)`]}
        {...remainingProps}
      ></video>
      {overlayImage && !active && (
        <div>
          <Image
            imageData={overlayImage}
            css={[
              tw`absolute top-0 left-0 object-cover w-full h-full`,
              overlayImageStyles,
            ]}
          />
          <Play
            css={tw`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
          />
        </div>
      )}
    </div>
  )
}

export default VideoS3
